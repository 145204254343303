/***BACKGROUND PARTICLE ANIM 1 (FIREFLY)***/
.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: var(--greenish2);
  opacity: 0.15;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw var(--greenish);
  animation: drift ease alternate infinite, flash ease infinite;
}
.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 15s;
}
.firefly:nth-child(1)::after {
  animation-duration: 15s, 10911ms;
  animation-delay: 0ms, 4546ms;
}
@keyframes move1 {
  0% {
    transform: translateX(5vw) translateY(19vh) scale(0.65);
  }
  4.54545% {
    transform: translateX(-35vw) translateY(21vh) scale(0.28);
  }
  9.09091% {
    transform: translateX(-44vw) translateY(-45vh) scale(0.62);
  }
  13.63636% {
    transform: translateX(-22vw) translateY(-33vh) scale(0.5);
  }
  18.18182% {
    transform: translateX(-46vw) translateY(-34vh) scale(0.83);
  }
  22.72727% {
    transform: translateX(-3vw) translateY(33vh) scale(0.94);
  }
  27.27273% {
    transform: translateX(38vw) translateY(-3vh) scale(0.99);
  }
  31.81818% {
    transform: translateX(-26vw) translateY(29vh) scale(0.5);
  }
  36.36364% {
    transform: translateX(18vw) translateY(-38vh) scale(0.86);
  }
  40.90909% {
    transform: translateX(2vw) translateY(-48vh) scale(0.64);
  }
  45.45455% {
    transform: translateX(10vw) translateY(-24vh) scale(0.73);
  }
  50% {
    transform: translateX(43vw) translateY(36vh) scale(0.26);
  }
  54.54545% {
    transform: translateX(-36vw) translateY(-33vh) scale(0.36);
  }
  59.09091% {
    transform: translateX(-37vw) translateY(-44vh) scale(0.69);
  }
  63.63636% {
    transform: translateX(-18vw) translateY(-13vh) scale(0.82);
  }
  68.18182% {
    transform: translateX(-16vw) translateY(45vh) scale(0.66);
  }
  72.72727% {
    transform: translateX(38vw) translateY(-46vh) scale(0.47);
  }
  77.27273% {
    transform: translateX(-45vw) translateY(31vh) scale(0.7);
  }
  81.81818% {
    transform: translateX(24vw) translateY(37vh) scale(0.49);
  }
  86.36364% {
    transform: translateX(0vw) translateY(10vh) scale(0.94);
  }
  90.90909% {
    transform: translateX(-29vw) translateY(3vh) scale(0.95);
  }
  95.45455% {
    transform: translateX(23vw) translateY(14vh) scale(0.61);
  }
  100% {
    transform: translateX(-6vw) translateY(39vh) scale(0.63);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 15s;
}
.firefly:nth-child(2)::after {
  animation-duration: 15s, 7522ms;
  animation-delay: 0ms, 4840ms;
}
@keyframes move2 {
  0% {
    transform: translateX(36vw) translateY(-32vh) scale(0.67);
  }
  3.57143% {
    transform: translateX(-26vw) translateY(15vh) scale(0.87);
  }
  7.14286% {
    transform: translateX(-14vw) translateY(6vh) scale(0.3);
  }
  10.71429% {
    transform: translateX(-47vw) translateY(4vh) scale(0.99);
  }
  14.28571% {
    transform: translateX(33vw) translateY(45vh) scale(0.49);
  }
  17.85714% {
    transform: translateX(20vw) translateY(9vh) scale(0.63);
  }
  21.42857% {
    transform: translateX(25vw) translateY(24vh) scale(0.77);
  }
  25% {
    transform: translateX(44vw) translateY(-13vh) scale(0.28);
  }
  28.57143% {
    transform: translateX(2vw) translateY(-27vh) scale(0.83);
  }
  32.14286% {
    transform: translateX(-29vw) translateY(32vh) scale(0.52);
  }
  35.71429% {
    transform: translateX(-19vw) translateY(-10vh) scale(0.9);
  }
  39.28571% {
    transform: translateX(7vw) translateY(-26vh) scale(0.93);
  }
  42.85714% {
    transform: translateX(-18vw) translateY(35vh) scale(0.92);
  }
  46.42857% {
    transform: translateX(15vw) translateY(39vh) scale(0.34);
  }
  50% {
    transform: translateX(33vw) translateY(12vh) scale(0.27);
  }
  53.57143% {
    transform: translateX(18vw) translateY(9vh) scale(0.34);
  }
  57.14286% {
    transform: translateX(7vw) translateY(6vh) scale(0.56);
  }
  60.71429% {
    transform: translateX(45vw) translateY(35vh) scale(0.82);
  }
  64.28571% {
    transform: translateX(-5vw) translateY(17vh) scale(0.34);
  }
  67.85714% {
    transform: translateX(43vw) translateY(24vh) scale(0.26);
  }
  71.42857% {
    transform: translateX(11vw) translateY(-49vh) scale(0.43);
  }
  75% {
    transform: translateX(-42vw) translateY(-46vh) scale(0.62);
  }
  78.57143% {
    transform: translateX(-20vw) translateY(-25vh) scale(0.57);
  }
  82.14286% {
    transform: translateX(12vw) translateY(6vh) scale(0.67);
  }
  85.71429% {
    transform: translateX(-47vw) translateY(30vh) scale(0.47);
  }
  89.28571% {
    transform: translateX(-5vw) translateY(45vh) scale(0.58);
  }
  92.85714% {
    transform: translateX(-8vw) translateY(6vh) scale(0.76);
  }
  96.42857% {
    transform: translateX(6vw) translateY(43vh) scale(0.28);
  }
  100% {
    transform: translateX(-18vw) translateY(33vh) scale(0.45);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 13s;
}
.firefly:nth-child(3)::after {
  animation-duration: 13s, 6486ms;
  animation-delay: 0ms, 6472ms;
}
@keyframes move3 {
  0% {
    transform: translateX(16vw) translateY(-11vh) scale(0.76);
  }
  5.55556% {
    transform: translateX(22vw) translateY(-40vh) scale(0.94);
  }
  11.11111% {
    transform: translateX(-15vw) translateY(-26vh) scale(0.9);
  }
  16.66667% {
    transform: translateX(-12vw) translateY(-34vh) scale(0.45);
  }
  22.22222% {
    transform: translateX(11vw) translateY(34vh) scale(0.83);
  }
  27.77778% {
    transform: translateX(0vw) translateY(-20vh) scale(0.26);
  }
  33.33333% {
    transform: translateX(-48vw) translateY(10vh) scale(0.46);
  }
  38.88889% {
    transform: translateX(16vw) translateY(47vh) scale(0.96);
  }
  44.44444% {
    transform: translateX(25vw) translateY(43vh) scale(0.52);
  }
  50% {
    transform: translateX(27vw) translateY(33vh) scale(0.55);
  }
  55.55556% {
    transform: translateX(-21vw) translateY(-21vh) scale(0.58);
  }
  61.11111% {
    transform: translateX(-25vw) translateY(42vh) scale(0.95);
  }
  66.66667% {
    transform: translateX(-37vw) translateY(30vh) scale(0.28);
  }
  72.22222% {
    transform: translateX(40vw) translateY(-35vh) scale(0.81);
  }
  77.77778% {
    transform: translateX(47vw) translateY(6vh) scale(0.95);
  }
  83.33333% {
    transform: translateX(-20vw) translateY(-34vh) scale(0.85);
  }
  88.88889% {
    transform: translateX(18vw) translateY(-26vh) scale(0.75);
  }
  94.44444% {
    transform: translateX(19vw) translateY(50vh) scale(0.33);
  }
  100% {
    transform: translateX(41vw) translateY(-23vh) scale(0.53);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 13s;
}
.firefly:nth-child(4)::after {
  animation-duration: 13s, 8400ms;
  animation-delay: 0ms, 7996ms;
}
@keyframes move4 {
  0% {
    transform: translateX(-3vw) translateY(-13vh) scale(0.75);
  }
  4% {
    transform: translateX(43vw) translateY(14vh) scale(0.57);
  }
  8% {
    transform: translateX(-44vw) translateY(-37vh) scale(0.96);
  }
  12% {
    transform: translateX(26vw) translateY(-47vh) scale(0.4);
  }
  16% {
    transform: translateX(-1vw) translateY(25vh) scale(0.5);
  }
  20% {
    transform: translateX(-25vw) translateY(15vh) scale(0.37);
  }
  24% {
    transform: translateX(-36vw) translateY(-34vh) scale(0.56);
  }
  28% {
    transform: translateX(-6vw) translateY(-47vh) scale(0.9);
  }
  32% {
    transform: translateX(44vw) translateY(-4vh) scale(0.72);
  }
  36% {
    transform: translateX(35vw) translateY(36vh) scale(0.46);
  }
  40% {
    transform: translateX(-10vw) translateY(2vh) scale(0.59);
  }
  44% {
    transform: translateX(32vw) translateY(-27vh) scale(0.45);
  }
  48% {
    transform: translateX(5vw) translateY(27vh) scale(0.92);
  }
  52% {
    transform: translateX(1vw) translateY(46vh) scale(0.43);
  }
  56% {
    transform: translateX(-34vw) translateY(-24vh) scale(0.62);
  }
  60% {
    transform: translateX(21vw) translateY(43vh) scale(0.82);
  }
  64% {
    transform: translateX(20vw) translateY(35vh) scale(0.28);
  }
  68% {
    transform: translateX(-25vw) translateY(-13vh) scale(0.8);
  }
  72% {
    transform: translateX(11vw) translateY(-12vh) scale(0.83);
  }
  76% {
    transform: translateX(-11vw) translateY(-2vh) scale(0.74);
  }
  80% {
    transform: translateX(15vw) translateY(-40vh) scale(0.66);
  }
  84% {
    transform: translateX(48vw) translateY(-1vh) scale(0.75);
  }
  88% {
    transform: translateX(22vw) translateY(19vh) scale(0.84);
  }
  92% {
    transform: translateX(50vw) translateY(24vh) scale(0.92);
  }
  96% {
    transform: translateX(0vw) translateY(30vh) scale(0.28);
  }
  100% {
    transform: translateX(-37vw) translateY(12vh) scale(0.68);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 14s;
}
.firefly:nth-child(5)::after {
  animation-duration: 14s, 7852ms;
  animation-delay: 0ms, 1445ms;
}
@keyframes move5 {
  0% {
    transform: translateX(42vw) translateY(9vh) scale(0.53);
  }
  4% {
    transform: translateX(-41vw) translateY(-20vh) scale(0.35);
  }
  8% {
    transform: translateX(49vw) translateY(-49vh) scale(0.38);
  }
  12% {
    transform: translateX(-15vw) translateY(-27vh) scale(0.36);
  }
  16% {
    transform: translateX(-47vw) translateY(10vh) scale(0.31);
  }
  20% {
    transform: translateX(18vw) translateY(46vh) scale(0.83);
  }
  24% {
    transform: translateX(-38vw) translateY(6vh) scale(0.44);
  }
  28% {
    transform: translateX(-48vw) translateY(36vh) scale(0.71);
  }
  32% {
    transform: translateX(35vw) translateY(9vh) scale(0.89);
  }
  36% {
    transform: translateX(-5vw) translateY(48vh) scale(0.92);
  }
  40% {
    transform: translateX(-45vw) translateY(-6vh) scale(0.31);
  }
  44% {
    transform: translateX(-2vw) translateY(-2vh) scale(0.35);
  }
  48% {
    transform: translateX(19vw) translateY(24vh) scale(0.32);
  }
  52% {
    transform: translateX(-42vw) translateY(4vh) scale(0.39);
  }
  56% {
    transform: translateX(9vw) translateY(-39vh) scale(0.27);
  }
  60% {
    transform: translateX(26vw) translateY(-40vh) scale(0.84);
  }
  64% {
    transform: translateX(19vw) translateY(-7vh) scale(0.41);
  }
  68% {
    transform: translateX(-15vw) translateY(25vh) scale(0.7);
  }
  72% {
    transform: translateX(-42vw) translateY(29vh) scale(0.54);
  }
  76% {
    transform: translateX(-10vw) translateY(38vh) scale(0.27);
  }
  80% {
    transform: translateX(39vw) translateY(45vh) scale(0.96);
  }
  84% {
    transform: translateX(11vw) translateY(31vh) scale(0.52);
  }
  88% {
    transform: translateX(20vw) translateY(-3vh) scale(0.93);
  }
  92% {
    transform: translateX(-5vw) translateY(-40vh) scale(0.63);
  }
  96% {
    transform: translateX(23vw) translateY(-36vh) scale(0.94);
  }
  100% {
    transform: translateX(6vw) translateY(-25vh) scale(0.33);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 11s;
}
.firefly:nth-child(6)::after {
  animation-duration: 11s, 8191ms;
  animation-delay: 0ms, 1966ms;
}
@keyframes move6 {
  0% {
    transform: translateX(6vw) translateY(1vh) scale(0.53);
  }
  4% {
    transform: translateX(9vw) translateY(-9vh) scale(0.94);
  }
  8% {
    transform: translateX(37vw) translateY(25vh) scale(0.26);
  }
  12% {
    transform: translateX(47vw) translateY(44vh) scale(0.65);
  }
  16% {
    transform: translateX(24vw) translateY(-18vh) scale(0.91);
  }
  20% {
    transform: translateX(39vw) translateY(20vh) scale(0.5);
  }
  24% {
    transform: translateX(-12vw) translateY(-10vh) scale(0.76);
  }
  28% {
    transform: translateX(22vw) translateY(-5vh) scale(0.82);
  }
  32% {
    transform: translateX(9vw) translateY(-7vh) scale(0.92);
  }
  36% {
    transform: translateX(-43vw) translateY(-44vh) scale(0.91);
  }
  40% {
    transform: translateX(-30vw) translateY(-14vh) scale(0.73);
  }
  44% {
    transform: translateX(24vw) translateY(-46vh) scale(0.66);
  }
  48% {
    transform: translateX(30vw) translateY(41vh) scale(0.76);
  }
  52% {
    transform: translateX(-37vw) translateY(-26vh) scale(0.29);
  }
  56% {
    transform: translateX(-37vw) translateY(-26vh) scale(0.61);
  }
  60% {
    transform: translateX(20vw) translateY(-1vh) scale(0.89);
  }
  64% {
    transform: translateX(40vw) translateY(-32vh) scale(0.57);
  }
  68% {
    transform: translateX(-26vw) translateY(-42vh) scale(0.42);
  }
  72% {
    transform: translateX(-31vw) translateY(-36vh) scale(0.95);
  }
  76% {
    transform: translateX(-17vw) translateY(50vh) scale(0.29);
  }
  80% {
    transform: translateX(-33vw) translateY(23vh) scale(0.8);
  }
  84% {
    transform: translateX(-46vw) translateY(32vh) scale(0.53);
  }
  88% {
    transform: translateX(-46vw) translateY(-10vh) scale(0.53);
  }
  92% {
    transform: translateX(-21vw) translateY(18vh) scale(0.84);
  }
  96% {
    transform: translateX(12vw) translateY(36vh) scale(0.76);
  }
  100% {
    transform: translateX(37vw) translateY(-40vh) scale(0.45);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 16s;
}
.firefly:nth-child(7)::after {
  animation-duration: 16s, 5635ms;
  animation-delay: 0ms, 2631ms;
}
@keyframes move7 {
  0% {
    transform: translateX(39vw) translateY(-29vh) scale(0.41);
  }
  4% {
    transform: translateX(1vw) translateY(7vh) scale(0.45);
  }
  8% {
    transform: translateX(6vw) translateY(-17vh) scale(0.66);
  }
  12% {
    transform: translateX(27vw) translateY(-48vh) scale(0.33);
  }
  16% {
    transform: translateX(-17vw) translateY(-26vh) scale(0.57);
  }
  20% {
    transform: translateX(37vw) translateY(-27vh) scale(0.72);
  }
  24% {
    transform: translateX(41vw) translateY(-42vh) scale(0.69);
  }
  28% {
    transform: translateX(41vw) translateY(-26vh) scale(0.43);
  }
  32% {
    transform: translateX(-11vw) translateY(33vh) scale(0.92);
  }
  36% {
    transform: translateX(4vw) translateY(45vh) scale(0.7);
  }
  40% {
    transform: translateX(-13vw) translateY(-7vh) scale(0.35);
  }
  44% {
    transform: translateX(40vw) translateY(-14vh) scale(0.86);
  }
  48% {
    transform: translateX(-44vw) translateY(-3vh) scale(0.58);
  }
  52% {
    transform: translateX(-25vw) translateY(25vh) scale(0.72);
  }
  56% {
    transform: translateX(-31vw) translateY(33vh) scale(0.94);
  }
  60% {
    transform: translateX(45vw) translateY(-31vh) scale(0.91);
  }
  64% {
    transform: translateX(-5vw) translateY(26vh) scale(0.82);
  }
  68% {
    transform: translateX(-39vw) translateY(28vh) scale(0.71);
  }
  72% {
    transform: translateX(9vw) translateY(2vh) scale(0.32);
  }
  76% {
    transform: translateX(30vw) translateY(-4vh) scale(0.84);
  }
  80% {
    transform: translateX(-36vw) translateY(-27vh) scale(0.36);
  }
  84% {
    transform: translateX(23vw) translateY(1vh) scale(0.77);
  }
  88% {
    transform: translateX(35vw) translateY(41vh) scale(0.27);
  }
  92% {
    transform: translateX(-10vw) translateY(-32vh) scale(0.43);
  }
  96% {
    transform: translateX(-14vw) translateY(21vh) scale(0.67);
  }
  100% {
    transform: translateX(23vw) translateY(2vh) scale(0.62);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 15s;
}
.firefly:nth-child(8)::after {
  animation-duration: 15s, 6163ms;
  animation-delay: 0ms, 2529ms;
}
@keyframes move8 {
  0% {
    transform: translateX(41vw) translateY(27vh) scale(0.86);
  }
  4% {
    transform: translateX(23vw) translateY(-20vh) scale(0.43);
  }
  8% {
    transform: translateX(17vw) translateY(-29vh) scale(0.72);
  }
  12% {
    transform: translateX(-47vw) translateY(3vh) scale(0.72);
  }
  16% {
    transform: translateX(-34vw) translateY(-43vh) scale(0.61);
  }
  20% {
    transform: translateX(19vw) translateY(31vh) scale(0.32);
  }
  24% {
    transform: translateX(-40vw) translateY(-26vh) scale(0.34);
  }
  28% {
    transform: translateX(-38vw) translateY(-32vh) scale(0.9);
  }
  32% {
    transform: translateX(-27vw) translateY(-10vh) scale(0.64);
  }
  36% {
    transform: translateX(-32vw) translateY(45vh) scale(0.9);
  }
  40% {
    transform: translateX(-32vw) translateY(-14vh) scale(0.93);
  }
  44% {
    transform: translateX(13vw) translateY(-13vh) scale(0.37);
  }
  48% {
    transform: translateX(28vw) translateY(40vh) scale(0.81);
  }
  52% {
    transform: translateX(-48vw) translateY(49vh) scale(0.34);
  }
  56% {
    transform: translateX(37vw) translateY(14vh) scale(0.98);
  }
  60% {
    transform: translateX(29vw) translateY(7vh) scale(0.76);
  }
  64% {
    transform: translateX(30vw) translateY(48vh) scale(0.89);
  }
  68% {
    transform: translateX(-47vw) translateY(11vh) scale(0.42);
  }
  72% {
    transform: translateX(-40vw) translateY(-25vh) scale(0.86);
  }
  76% {
    transform: translateX(29vw) translateY(16vh) scale(0.91);
  }
  80% {
    transform: translateX(-41vw) translateY(-37vh) scale(0.38);
  }
  84% {
    transform: translateX(32vw) translateY(30vh) scale(0.78);
  }
  88% {
    transform: translateX(-34vw) translateY(-31vh) scale(0.93);
  }
  92% {
    transform: translateX(-15vw) translateY(40vh) scale(0.27);
  }
  96% {
    transform: translateX(-37vw) translateY(-2vh) scale(0.4);
  }
  100% {
    transform: translateX(-25vw) translateY(-7vh) scale(0.6);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 10s;
}
.firefly:nth-child(9)::after {
  animation-duration: 10s, 6390ms;
  animation-delay: 0ms, 2607ms;
}
@keyframes move9 {
  0% {
    transform: translateX(22vw) translateY(-9vh) scale(0.9);
  }
  3.57143% {
    transform: translateX(7vw) translateY(2vh) scale(0.4);
  }
  7.14286% {
    transform: translateX(-45vw) translateY(12vh) scale(0.34);
  }
  10.71429% {
    transform: translateX(5vw) translateY(6vh) scale(0.26);
  }
  14.28571% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.31);
  }
  17.85714% {
    transform: translateX(-33vw) translateY(-19vh) scale(0.66);
  }
  21.42857% {
    transform: translateX(-38vw) translateY(20vh) scale(0.54);
  }
  25% {
    transform: translateX(1vw) translateY(6vh) scale(0.89);
  }
  28.57143% {
    transform: translateX(-27vw) translateY(-28vh) scale(0.31);
  }
  32.14286% {
    transform: translateX(-42vw) translateY(-6vh) scale(0.58);
  }
  35.71429% {
    transform: translateX(-25vw) translateY(14vh) scale(0.73);
  }
  39.28571% {
    transform: translateX(-41vw) translateY(-37vh) scale(0.79);
  }
  42.85714% {
    transform: translateX(33vw) translateY(-8vh) scale(0.29);
  }
  46.42857% {
    transform: translateX(18vw) translateY(-34vh) scale(0.95);
  }
  50% {
    transform: translateX(-27vw) translateY(18vh) scale(0.83);
  }
  53.57143% {
    transform: translateX(4vw) translateY(36vh) scale(0.49);
  }
  57.14286% {
    transform: translateX(-14vw) translateY(-2vh) scale(0.99);
  }
  60.71429% {
    transform: translateX(37vw) translateY(24vh) scale(0.61);
  }
  64.28571% {
    transform: translateX(26vw) translateY(44vh) scale(0.38);
  }
  67.85714% {
    transform: translateX(-43vw) translateY(44vh) scale(0.81);
  }
  71.42857% {
    transform: translateX(-41vw) translateY(-39vh) scale(0.76);
  }
  75% {
    transform: translateX(-6vw) translateY(-13vh) scale(0.81);
  }
  78.57143% {
    transform: translateX(-43vw) translateY(29vh) scale(0.67);
  }
  82.14286% {
    transform: translateX(-14vw) translateY(-25vh) scale(0.65);
  }
  85.71429% {
    transform: translateX(43vw) translateY(-4vh) scale(0.83);
  }
  89.28571% {
    transform: translateX(4vw) translateY(-6vh) scale(0.37);
  }
  92.85714% {
    transform: translateX(-26vw) translateY(7vh) scale(0.73);
  }
  96.42857% {
    transform: translateX(25vw) translateY(-46vh) scale(0.96);
  }
  100% {
    transform: translateX(-29vw) translateY(-19vh) scale(0.78);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 17s;
}
.firefly:nth-child(10)::after {
  animation-duration: 17s, 10176ms;
  animation-delay: 0ms, 8234ms;
}
@keyframes move10 {
  0% {
    transform: translateX(-21vw) translateY(-16vh) scale(0.33);
  }
  3.84615% {
    transform: translateX(15vw) translateY(35vh) scale(0.45);
  }
  7.69231% {
    transform: translateX(-30vw) translateY(-19vh) scale(0.68);
  }
  11.53846% {
    transform: translateX(22vw) translateY(38vh) scale(0.9);
  }
  15.38462% {
    transform: translateX(29vw) translateY(-8vh) scale(0.37);
  }
  19.23077% {
    transform: translateX(2vw) translateY(-7vh) scale(0.39);
  }
  23.07692% {
    transform: translateX(12vw) translateY(-10vh) scale(0.53);
  }
  26.92308% {
    transform: translateX(20vw) translateY(46vh) scale(0.26);
  }
  30.76923% {
    transform: translateX(16vw) translateY(-28vh) scale(0.7);
  }
  34.61538% {
    transform: translateX(13vw) translateY(35vh) scale(0.3);
  }
  38.46154% {
    transform: translateX(10vw) translateY(-9vh) scale(0.4);
  }
  42.30769% {
    transform: translateX(-29vw) translateY(50vh) scale(0.69);
  }
  46.15385% {
    transform: translateX(10vw) translateY(39vh) scale(0.38);
  }
  50% {
    transform: translateX(-32vw) translateY(-4vh) scale(0.79);
  }
  53.84615% {
    transform: translateX(-3vw) translateY(35vh) scale(0.41);
  }
  57.69231% {
    transform: translateX(6vw) translateY(-6vh) scale(0.38);
  }
  61.53846% {
    transform: translateX(-18vw) translateY(-36vh) scale(0.65);
  }
  65.38462% {
    transform: translateX(-10vw) translateY(-33vh) scale(0.72);
  }
  69.23077% {
    transform: translateX(-22vw) translateY(-16vh) scale(0.63);
  }
  73.07692% {
    transform: translateX(-9vw) translateY(28vh) scale(0.67);
  }
  76.92308% {
    transform: translateX(23vw) translateY(-46vh) scale(0.42);
  }
  80.76923% {
    transform: translateX(-21vw) translateY(-49vh) scale(0.95);
  }
  84.61538% {
    transform: translateX(2vw) translateY(45vh) scale(0.68);
  }
  88.46154% {
    transform: translateX(1vw) translateY(15vh) scale(0.97);
  }
  92.30769% {
    transform: translateX(22vw) translateY(-16vh) scale(0.52);
  }
  96.15385% {
    transform: translateX(13vw) translateY(43vh) scale(0.64);
  }
  100% {
    transform: translateX(36vw) translateY(-17vh) scale(0.74);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 15s;
}
.firefly:nth-child(11)::after {
  animation-duration: 15s, 6363ms;
  animation-delay: 0ms, 4963ms;
}

@keyframes move11 {
  0% {
    transform: translateX(28vw) translateY(15vh) scale(0.96);
  }
  3.57143% {
    transform: translateX(-42vw) translateY(41vh) scale(0.78);
  }
  7.14286% {
    transform: translateX(47vw) translateY(-1vh) scale(0.88);
  }
  10.71429% {
    transform: translateX(-43vw) translateY(-29vh) scale(0.8);
  }
  14.28571% {
    transform: translateX(47vw) translateY(7vh) scale(0.47);
  }
  17.85714% {
    transform: translateX(14vw) translateY(22vh) scale(0.7);
  }
  21.42857% {
    transform: translateX(-15vw) translateY(8vh) scale(0.76);
  }
  25% {
    transform: translateX(1vw) translateY(24vh) scale(0.93);
  }
  28.57143% {
    transform: translateX(-35vw) translateY(30vh) scale(0.83);
  }
  32.14286% {
    transform: translateX(-19vw) translateY(6vh) scale(0.82);
  }
  35.71429% {
    transform: translateX(44vw) translateY(17vh) scale(0.44);
  }
  39.28571% {
    transform: translateX(5vw) translateY(-29vh) scale(0.84);
  }
  42.85714% {
    transform: translateX(-30vw) translateY(-27vh) scale(0.62);
  }
  46.42857% {
    transform: translateX(-34vw) translateY(-15vh) scale(0.33);
  }
  50% {
    transform: translateX(4vw) translateY(17vh) scale(0.6);
  }
  53.57143% {
    transform: translateX(-26vw) translateY(-41vh) scale(0.75);
  }
  57.14286% {
    transform: translateX(-35vw) translateY(3vh) scale(0.59);
  }
  60.71429% {
    transform: translateX(36vw) translateY(-22vh) scale(0.61);
  }
  64.28571% {
    transform: translateX(45vw) translateY(-5vh) scale(0.86);
  }
  67.85714% {
    transform: translateX(26vw) translateY(-24vh) scale(0.44);
  }
  71.42857% {
    transform: translateX(48vw) translateY(-28vh) scale(0.52);
  }
  75% {
    transform: translateX(34vw) translateY(-38vh) scale(0.82);
  }
  78.57143% {
    transform: translateX(-18vw) translateY(-19vh) scale(0.97);
  }
  82.14286% {
    transform: translateX(40vw) translateY(-18vh) scale(0.6);
  }
  85.71429% {
    transform: translateX(46vw) translateY(11vh) scale(0.29);
  }
  89.28571% {
    transform: translateX(-11vw) translateY(-30vh) scale(0.57);
  }
  92.85714% {
    transform: translateX(-16vw) translateY(-40vh) scale(0.49);
  }
  96.42857% {
    transform: translateX(23vw) translateY(32vh) scale(0.34);
  }
  100% {
    transform: translateX(-30vw) translateY(13vh) scale(0.98);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 9s;
}
.firefly:nth-child(12)::after {
  animation-duration: 9s, 9444ms;
  animation-delay: 0ms, 7635ms;
}

@keyframes move12 {
  0% {
    transform: translateX(44vw) translateY(-6vh) scale(0.9);
  }
  4.16667% {
    transform: translateX(-30vw) translateY(-27vh) scale(0.59);
  }
  8.33333% {
    transform: translateX(44vw) translateY(-3vh) scale(0.48);
  }
  12.5% {
    transform: translateX(-10vw) translateY(18vh) scale(0.54);
  }
  16.66667% {
    transform: translateX(-38vw) translateY(-33vh) scale(0.51);
  }
  20.83333% {
    transform: translateX(-25vw) translateY(38vh) scale(0.77);
  }
  25% {
    transform: translateX(37vw) translateY(47vh) scale(0.9);
  }
  29.16667% {
    transform: translateX(34vw) translateY(-38vh) scale(0.8);
  }
  33.33333% {
    transform: translateX(13vw) translateY(-40vh) scale(0.96);
  }
  37.5% {
    transform: translateX(-27vw) translateY(-49vh) scale(0.78);
  }
  41.66667% {
    transform: translateX(-28vw) translateY(-40vh) scale(0.37);
  }
  45.83333% {
    transform: translateX(-33vw) translateY(42vh) scale(0.28);
  }
  50% {
    transform: translateX(-2vw) translateY(4vh) scale(0.52);
  }
  54.16667% {
    transform: translateX(27vw) translateY(6vh) scale(0.87);
  }
  58.33333% {
    transform: translateX(9vw) translateY(32vh) scale(0.36);
  }
  62.5% {
    transform: translateX(-19vw) translateY(33vh) scale(0.71);
  }
  66.66667% {
    transform: translateX(17vw) translateY(-22vh) scale(0.53);
  }
  70.83333% {
    transform: translateX(31vw) translateY(32vh) scale(0.49);
  }
  75% {
    transform: translateX(-35vw) translateY(-20vh) scale(0.63);
  }
  79.16667% {
    transform: translateX(-14vw) translateY(22vh) scale(0.86);
  }
  83.33333% {
    transform: translateX(21vw) translateY(20vh) scale(0.59);
  }
  87.5% {
    transform: translateX(-43vw) translateY(-23vh) scale(0.57);
  }
  91.66667% {
    transform: translateX(21vw) translateY(13vh) scale(0.49);
  }
  95.83333% {
    transform: translateX(-36vw) translateY(4vh) scale(0.88);
  }
  100% {
    transform: translateX(-25vw) translateY(11vh) scale(0.89);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 13s;
}
.firefly:nth-child(13)::after {
  animation-duration: 13s, 5068ms;
  animation-delay: 0ms, 6435ms;
}

@keyframes move13 {
  0% {
    transform: translateX(22vw) translateY(-28vh) scale(0.94);
  }
  4.16667% {
    transform: translateX(19vw) translateY(39vh) scale(0.73);
  }
  8.33333% {
    transform: translateX(34vw) translateY(16vh) scale(0.51);
  }
  12.5% {
    transform: translateX(-46vw) translateY(40vh) scale(0.68);
  }
  16.66667% {
    transform: translateX(-48vw) translateY(42vh) scale(0.36);
  }
  20.83333% {
    transform: translateX(17vw) translateY(-19vh) scale(0.68);
  }
  25% {
    transform: translateX(-47vw) translateY(42vh) scale(0.27);
  }
  29.16667% {
    transform: translateX(2vw) translateY(28vh) scale(0.39);
  }
  33.33333% {
    transform: translateX(5vw) translateY(-34vh) scale(0.85);
  }
  37.5% {
    transform: translateX(-22vw) translateY(9vh) scale(0.78);
  }
  41.66667% {
    transform: translateX(38vw) translateY(-16vh) scale(0.58);
  }
  45.83333% {
    transform: translateX(0vw) translateY(-40vh) scale(0.59);
  }
  50% {
    transform: translateX(-4vw) translateY(0vh) scale(0.34);
  }
  54.16667% {
    transform: translateX(7vw) translateY(-10vh) scale(0.6);
  }
  58.33333% {
    transform: translateX(-6vw) translateY(-18vh) scale(0.78);
  }
  62.5% {
    transform: translateX(32vw) translateY(37vh) scale(0.55);
  }
  66.66667% {
    transform: translateX(29vw) translateY(1vh) scale(0.67);
  }
  70.83333% {
    transform: translateX(36vw) translateY(-1vh) scale(0.28);
  }
  75% {
    transform: translateX(25vw) translateY(-7vh) scale(0.93);
  }
  79.16667% {
    transform: translateX(9vw) translateY(28vh) scale(0.3);
  }
  83.33333% {
    transform: translateX(50vw) translateY(-29vh) scale(0.98);
  }
  87.5% {
    transform: translateX(-18vw) translateY(46vh) scale(0.57);
  }
  91.66667% {
    transform: translateX(42vw) translateY(-15vh) scale(0.4);
  }
  95.83333% {
    transform: translateX(19vw) translateY(-40vh) scale(0.53);
  }
  100% {
    transform: translateX(1vw) translateY(-40vh) scale(0.88);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 9s;
}
.firefly:nth-child(14)::after {
  animation-duration: 9s, 10346ms;
  animation-delay: 0ms, 7854ms;
}

@keyframes move14 {
  0% {
    transform: translateX(-24vw) translateY(46vh) scale(0.34);
  }
  5.88235% {
    transform: translateX(34vw) translateY(50vh) scale(0.6);
  }
  11.76471% {
    transform: translateX(13vw) translateY(37vh) scale(0.68);
  }
  17.64706% {
    transform: translateX(-22vw) translateY(20vh) scale(0.95);
  }
  23.52941% {
    transform: translateX(-1vw) translateY(21vh) scale(0.68);
  }
  29.41176% {
    transform: translateX(31vw) translateY(10vh) scale(0.6);
  }
  35.29412% {
    transform: translateX(-45vw) translateY(34vh) scale(0.89);
  }
  41.17647% {
    transform: translateX(44vw) translateY(-49vh) scale(0.3);
  }
  47.05882% {
    transform: translateX(35vw) translateY(11vh) scale(0.56);
  }
  52.94118% {
    transform: translateX(-49vw) translateY(7vh) scale(0.45);
  }
  58.82353% {
    transform: translateX(-17vw) translateY(-38vh) scale(0.71);
  }
  64.70588% {
    transform: translateX(4vw) translateY(27vh) scale(0.45);
  }
  70.58824% {
    transform: translateX(12vw) translateY(-36vh) scale(0.54);
  }
  76.47059% {
    transform: translateX(40vw) translateY(-12vh) scale(0.37);
  }
  82.35294% {
    transform: translateX(-4vw) translateY(36vh) scale(0.7);
  }
  88.23529% {
    transform: translateX(-36vw) translateY(50vh) scale(0.89);
  }
  94.11765% {
    transform: translateX(49vw) translateY(31vh) scale(0.64);
  }
  100% {
    transform: translateX(14vw) translateY(-32vh) scale(0.83);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 11s;
}
.firefly:nth-child(15)::after {
  animation-duration: 11s, 9178ms;
  animation-delay: 0ms, 3279ms;
}

@keyframes move15 {
  0% {
    transform: translateX(20vw) translateY(6vh) scale(0.72);
  }
  5.55556% {
    transform: translateX(13vw) translateY(3vh) scale(0.78);
  }
  11.11111% {
    transform: translateX(-6vw) translateY(39vh) scale(0.97);
  }
  16.66667% {
    transform: translateX(1vw) translateY(45vh) scale(0.34);
  }
  22.22222% {
    transform: translateX(-35vw) translateY(-43vh) scale(0.54);
  }
  27.77778% {
    transform: translateX(4vw) translateY(-38vh) scale(0.47);
  }
  33.33333% {
    transform: translateX(14vw) translateY(45vh) scale(0.65);
  }
  38.88889% {
    transform: translateX(40vw) translateY(11vh) scale(0.5);
  }
  44.44444% {
    transform: translateX(6vw) translateY(39vh) scale(0.83);
  }
  50% {
    transform: translateX(13vw) translateY(17vh) scale(0.36);
  }
  55.55556% {
    transform: translateX(33vw) translateY(17vh) scale(0.58);
  }
  61.11111% {
    transform: translateX(-6vw) translateY(-14vh) scale(0.57);
  }
  66.66667% {
    transform: translateX(22vw) translateY(-9vh) scale(0.45);
  }
  72.22222% {
    transform: translateX(15vw) translateY(43vh) scale(0.76);
  }
  77.77778% {
    transform: translateX(1vw) translateY(23vh) scale(0.69);
  }
  83.33333% {
    transform: translateX(41vw) translateY(19vh) scale(0.34);
  }
  88.88889% {
    transform: translateX(25vw) translateY(19vh) scale(0.62);
  }
  94.44444% {
    transform: translateX(-49vw) translateY(8vh) scale(0.89);
  }
  100% {
    transform: translateX(40vw) translateY(-19vh) scale(0.29);
  }
}

.firefly:nth-child(16) {
  animation-name: move16;
}
.firefly:nth-child(16)::before {
  animation-duration: 10s;
}
.firefly:nth-child(16)::after {
  animation-duration: 10s, 6354ms;
  animation-delay: 0ms, 2277ms;
}

@keyframes move16 {
  0% {
    transform: translateX(32vw) translateY(-23vh) scale(0.34);
  }
  5.88235% {
    transform: translateX(18vw) translateY(-22vh) scale(0.38);
  }
  11.76471% {
    transform: translateX(-22vw) translateY(17vh) scale(0.64);
  }
  17.64706% {
    transform: translateX(2vw) translateY(15vh) scale(0.95);
  }
  23.52941% {
    transform: translateX(-38vw) translateY(38vh) scale(0.4);
  }
  29.41176% {
    transform: translateX(13vw) translateY(-13vh) scale(0.63);
  }
  35.29412% {
    transform: translateX(26vw) translateY(31vh) scale(0.43);
  }
  41.17647% {
    transform: translateX(-24vw) translateY(41vh) scale(0.69);
  }
  47.05882% {
    transform: translateX(12vw) translateY(17vh) scale(0.83);
  }
  52.94118% {
    transform: translateX(-19vw) translateY(-27vh) scale(0.49);
  }
  58.82353% {
    transform: translateX(-19vw) translateY(-23vh) scale(0.58);
  }
  64.70588% {
    transform: translateX(45vw) translateY(-32vh) scale(0.33);
  }
  70.58824% {
    transform: translateX(-17vw) translateY(39vh) scale(0.47);
  }
  76.47059% {
    transform: translateX(-20vw) translateY(-29vh) scale(0.39);
  }
  82.35294% {
    transform: translateX(10vw) translateY(-43vh) scale(0.4);
  }
  88.23529% {
    transform: translateX(-33vw) translateY(37vh) scale(0.39);
  }
  94.11765% {
    transform: translateX(-17vw) translateY(33vh) scale(0.71);
  }
  100% {
    transform: translateX(3vw) translateY(-35vh) scale(0.42);
  }
}

.firefly:nth-child(17) {
  animation-name: move17;
}
.firefly:nth-child(17)::before {
  animation-duration: 10s;
}
.firefly:nth-child(17)::after {
  animation-duration: 10s, 9919ms;
  animation-delay: 0ms, 2411ms;
}

@keyframes move17 {
  0% {
    transform: translateX(-48vw) translateY(35vh) scale(0.53);
  }
  4.7619% {
    transform: translateX(-10vw) translateY(-15vh) scale(0.93);
  }
  9.52381% {
    transform: translateX(32vw) translateY(23vh) scale(0.76);
  }
  14.28571% {
    transform: translateX(-21vw) translateY(38vh) scale(0.9);
  }
  19.04762% {
    transform: translateX(34vw) translateY(-12vh) scale(0.91);
  }
  23.80952% {
    transform: translateX(-35vw) translateY(33vh) scale(0.67);
  }
  28.57143% {
    transform: translateX(32vw) translateY(-30vh) scale(0.65);
  }
  33.33333% {
    transform: translateX(44vw) translateY(12vh) scale(0.66);
  }
  38.09524% {
    transform: translateX(40vw) translateY(30vh) scale(0.58);
  }
  42.85714% {
    transform: translateX(-25vw) translateY(25vh) scale(0.82);
  }
  47.61905% {
    transform: translateX(-16vw) translateY(-18vh) scale(0.66);
  }
  52.38095% {
    transform: translateX(-44vw) translateY(-6vh) scale(0.41);
  }
  57.14286% {
    transform: translateX(1vw) translateY(32vh) scale(0.78);
  }
  61.90476% {
    transform: translateX(43vw) translateY(-24vh) scale(0.31);
  }
  66.66667% {
    transform: translateX(-43vw) translateY(49vh) scale(0.49);
  }
  71.42857% {
    transform: translateX(7vw) translateY(-3vh) scale(0.58);
  }
  76.19048% {
    transform: translateX(6vw) translateY(42vh) scale(0.9);
  }
  80.95238% {
    transform: translateX(-17vw) translateY(22vh) scale(0.28);
  }
  85.71429% {
    transform: translateX(-11vw) translateY(15vh) scale(0.95);
  }
  90.47619% {
    transform: translateX(-47vw) translateY(-36vh) scale(1);
  }
  95.2381% {
    transform: translateX(-34vw) translateY(35vh) scale(0.37);
  }
  100% {
    transform: translateX(-27vw) translateY(26vh) scale(0.55);
  }
}

.firefly:nth-child(18) {
  animation-name: move18;
}
.firefly:nth-child(18)::before {
  animation-duration: 16s;
}
.firefly:nth-child(18)::after {
  animation-duration: 16s, 10601ms;
  animation-delay: 0ms, 6044ms;
}

@keyframes move18 {
  0% {
    transform: translateX(49vw) translateY(-43vh) scale(0.59);
  }
  5.55556% {
    transform: translateX(-11vw) translateY(-45vh) scale(0.7);
  }
  11.11111% {
    transform: translateX(34vw) translateY(32vh) scale(0.85);
  }
  16.66667% {
    transform: translateX(15vw) translateY(27vh) scale(0.62);
  }
  22.22222% {
    transform: translateX(0vw) translateY(34vh) scale(0.45);
  }
  27.77778% {
    transform: translateX(-15vw) translateY(47vh) scale(0.61);
  }
  33.33333% {
    transform: translateX(-31vw) translateY(-3vh) scale(0.61);
  }
  38.88889% {
    transform: translateX(-10vw) translateY(-29vh) scale(0.44);
  }
  44.44444% {
    transform: translateX(19vw) translateY(-42vh) scale(0.34);
  }
  50% {
    transform: translateX(-1vw) translateY(0vh) scale(0.74);
  }
  55.55556% {
    transform: translateX(-19vw) translateY(-35vh) scale(0.86);
  }
  61.11111% {
    transform: translateX(27vw) translateY(-37vh) scale(0.89);
  }
  66.66667% {
    transform: translateX(0vw) translateY(-37vh) scale(0.49);
  }
  72.22222% {
    transform: translateX(-13vw) translateY(-19vh) scale(0.95);
  }
  77.77778% {
    transform: translateX(12vw) translateY(0vh) scale(0.81);
  }
  83.33333% {
    transform: translateX(-5vw) translateY(36vh) scale(0.5);
  }
  88.88889% {
    transform: translateX(-40vw) translateY(37vh) scale(0.27);
  }
  94.44444% {
    transform: translateX(-49vw) translateY(-48vh) scale(0.87);
  }
  100% {
    transform: translateX(-6vw) translateY(29vh) scale(0.42);
  }
}

.firefly:nth-child(19) {
  animation-name: move19;
}
.firefly:nth-child(19)::before {
  animation-duration: 14s;
}
.firefly:nth-child(19)::after {
  animation-duration: 14s, 5697ms;
  animation-delay: 0ms, 2615ms;
}

@keyframes move19 {
  0% {
    transform: translateX(-27vw) translateY(23vh) scale(0.65);
  }
  5.88235% {
    transform: translateX(9vw) translateY(-34vh) scale(0.82);
  }
  11.76471% {
    transform: translateX(15vw) translateY(42vh) scale(0.31);
  }
  17.64706% {
    transform: translateX(-38vw) translateY(44vh) scale(0.58);
  }
  23.52941% {
    transform: translateX(-7vw) translateY(0vh) scale(0.93);
  }
  29.41176% {
    transform: translateX(-8vw) translateY(18vh) scale(0.26);
  }
  35.29412% {
    transform: translateX(-3vw) translateY(34vh) scale(0.69);
  }
  41.17647% {
    transform: translateX(39vw) translateY(38vh) scale(0.83);
  }
  47.05882% {
    transform: translateX(-29vw) translateY(7vh) scale(0.62);
  }
  52.94118% {
    transform: translateX(-14vw) translateY(18vh) scale(0.55);
  }
  58.82353% {
    transform: translateX(-39vw) translateY(-3vh) scale(0.62);
  }
  64.70588% {
    transform: translateX(-16vw) translateY(-37vh) scale(0.56);
  }
  70.58824% {
    transform: translateX(-23vw) translateY(-40vh) scale(0.65);
  }
  76.47059% {
    transform: translateX(-44vw) translateY(-20vh) scale(0.53);
  }
  82.35294% {
    transform: translateX(17vw) translateY(-39vh) scale(0.4);
  }
  88.23529% {
    transform: translateX(-6vw) translateY(-14vh) scale(0.75);
  }
  94.11765% {
    transform: translateX(4vw) translateY(-29vh) scale(0.6);
  }
  100% {
    transform: translateX(37vw) translateY(-21vh) scale(0.62);
  }
}

.firefly:nth-child(20) {
  animation-name: move20;
}
.firefly:nth-child(20)::before {
  animation-duration: 14s;
}
.firefly:nth-child(20)::after {
  animation-duration: 14s, 7090ms;
  animation-delay: 0ms, 1277ms;
}

@keyframes move20 {
  0% {
    transform: translateX(-5vw) translateY(31vh) scale(0.42);
  }
  4.16667% {
    transform: translateX(-15vw) translateY(36vh) scale(0.86);
  }
  8.33333% {
    transform: translateX(-32vw) translateY(18vh) scale(0.7);
  }
  12.5% {
    transform: translateX(10vw) translateY(-20vh) scale(0.83);
  }
  16.66667% {
    transform: translateX(-45vw) translateY(46vh) scale(0.8);
  }
  20.83333% {
    transform: translateX(23vw) translateY(16vh) scale(0.46);
  }
  25% {
    transform: translateX(7vw) translateY(43vh) scale(0.56);
  }
  29.16667% {
    transform: translateX(13vw) translateY(9vh) scale(0.54);
  }
  33.33333% {
    transform: translateX(-22vw) translateY(42vh) scale(0.46);
  }
  37.5% {
    transform: translateX(4vw) translateY(-10vh) scale(0.29);
  }
  41.66667% {
    transform: translateX(20vw) translateY(35vh) scale(0.65);
  }
  45.83333% {
    transform: translateX(-21vw) translateY(46vh) scale(0.86);
  }
  50% {
    transform: translateX(-8vw) translateY(38vh) scale(0.28);
  }
  54.16667% {
    transform: translateX(-20vw) translateY(26vh) scale(0.58);
  }
  58.33333% {
    transform: translateX(-41vw) translateY(-43vh) scale(0.49);
  }
  62.5% {
    transform: translateX(44vw) translateY(20vh) scale(0.67);
  }
  66.66667% {
    transform: translateX(-14vw) translateY(-20vh) scale(0.42);
  }
  70.83333% {
    transform: translateX(-43vw) translateY(-38vh) scale(0.63);
  }
  75% {
    transform: translateX(-8vw) translateY(-31vh) scale(0.32);
  }
  79.16667% {
    transform: translateX(15vw) translateY(36vh) scale(0.67);
  }
  83.33333% {
    transform: translateX(-10vw) translateY(46vh) scale(0.51);
  }
  87.5% {
    transform: translateX(17vw) translateY(-47vh) scale(0.51);
  }
  91.66667% {
    transform: translateX(41vw) translateY(1vh) scale(0.99);
  }
  95.83333% {
    transform: translateX(-11vw) translateY(24vh) scale(0.94);
  }
  100% {
    transform: translateX(50vw) translateY(47vh) scale(0.74);
  }
}

.firefly:nth-child(21) {
  animation-name: move21;
}
.firefly:nth-child(21)::before {
  animation-duration: 9s;
}
.firefly:nth-child(21)::after {
  animation-duration: 9s, 8387ms;
  animation-delay: 0ms, 4522ms;
}

@keyframes move21 {
  0% {
    transform: translateX(0vw) translateY(-34vh) scale(0.29);
  }
  4.34783% {
    transform: translateX(-44vw) translateY(30vh) scale(0.78);
  }
  8.69565% {
    transform: translateX(14vw) translateY(42vh) scale(0.47);
  }
  13.04348% {
    transform: translateX(-14vw) translateY(10vh) scale(0.57);
  }
  17.3913% {
    transform: translateX(23vw) translateY(-42vh) scale(0.43);
  }
  21.73913% {
    transform: translateX(22vw) translateY(9vh) scale(1);
  }
  26.08696% {
    transform: translateX(-34vw) translateY(-12vh) scale(0.82);
  }
  30.43478% {
    transform: translateX(-32vw) translateY(15vh) scale(0.63);
  }
  34.78261% {
    transform: translateX(-18vw) translateY(19vh) scale(0.26);
  }
  39.13043% {
    transform: translateX(-40vw) translateY(-6vh) scale(0.49);
  }
  43.47826% {
    transform: translateX(-10vw) translateY(-39vh) scale(0.68);
  }
  47.82609% {
    transform: translateX(38vw) translateY(42vh) scale(0.94);
  }
  52.17391% {
    transform: translateX(44vw) translateY(-25vh) scale(0.41);
  }
  56.52174% {
    transform: translateX(43vw) translateY(-30vh) scale(0.67);
  }
  60.86957% {
    transform: translateX(-47vw) translateY(17vh) scale(0.96);
  }
  65.21739% {
    transform: translateX(-36vw) translateY(28vh) scale(0.71);
  }
  69.56522% {
    transform: translateX(-34vw) translateY(-27vh) scale(0.47);
  }
  73.91304% {
    transform: translateX(44vw) translateY(5vh) scale(0.64);
  }
  78.26087% {
    transform: translateX(-33vw) translateY(-29vh) scale(0.71);
  }
  82.6087% {
    transform: translateX(16vw) translateY(31vh) scale(0.63);
  }
  86.95652% {
    transform: translateX(39vw) translateY(-3vh) scale(0.76);
  }
  91.30435% {
    transform: translateX(43vw) translateY(-2vh) scale(0.78);
  }
  95.65217% {
    transform: translateX(47vw) translateY(27vh) scale(0.36);
  }
  100% {
    transform: translateX(-35vw) translateY(-29vh) scale(0.39);
  }
}

.firefly:nth-child(22) {
  animation-name: move22;
}
.firefly:nth-child(22)::before {
  animation-duration: 11s;
}
.firefly:nth-child(22)::after {
  animation-duration: 11s, 5890ms;
  animation-delay: 0ms, 4800ms;
}

@keyframes move22 {
  0% {
    transform: translateX(-49vw) translateY(-19vh) scale(0.95);
  }
  5% {
    transform: translateX(21vw) translateY(39vh) scale(0.48);
  }
  10% {
    transform: translateX(-37vw) translateY(-12vh) scale(0.26);
  }
  15% {
    transform: translateX(21vw) translateY(1vh) scale(0.31);
  }
  20% {
    transform: translateX(-16vw) translateY(47vh) scale(0.3);
  }
  25% {
    transform: translateX(-14vw) translateY(4vh) scale(0.65);
  }
  30% {
    transform: translateX(-25vw) translateY(29vh) scale(0.7);
  }
  35% {
    transform: translateX(20vw) translateY(-30vh) scale(0.98);
  }
  40% {
    transform: translateX(6vw) translateY(-34vh) scale(0.7);
  }
  45% {
    transform: translateX(1vw) translateY(12vh) scale(0.38);
  }
  50% {
    transform: translateX(-4vw) translateY(-25vh) scale(0.93);
  }
  55% {
    transform: translateX(-44vw) translateY(8vh) scale(0.46);
  }
  60% {
    transform: translateX(5vw) translateY(-2vh) scale(0.92);
  }
  65% {
    transform: translateX(-36vw) translateY(-39vh) scale(0.48);
  }
  70% {
    transform: translateX(-23vw) translateY(49vh) scale(0.62);
  }
  75% {
    transform: translateX(8vw) translateY(-40vh) scale(0.52);
  }
  80% {
    transform: translateX(-12vw) translateY(-21vh) scale(0.96);
  }
  85% {
    transform: translateX(16vw) translateY(33vh) scale(0.26);
  }
  90% {
    transform: translateX(35vw) translateY(25vh) scale(0.59);
  }
  95% {
    transform: translateX(-44vw) translateY(-10vh) scale(0.61);
  }
  100% {
    transform: translateX(-42vw) translateY(-23vh) scale(0.53);
  }
}

.firefly:nth-child(23) {
  animation-name: move23;
}
.firefly:nth-child(23)::before {
  animation-duration: 15s;
}
.firefly:nth-child(23)::after {
  animation-duration: 15s, 9509ms;
  animation-delay: 0ms, 1007ms;
}

@keyframes move23 {
  0% {
    transform: translateX(-42vw) translateY(-15vh) scale(0.87);
  }
  4.16667% {
    transform: translateX(5vw) translateY(-44vh) scale(0.91);
  }
  8.33333% {
    transform: translateX(21vw) translateY(-28vh) scale(0.45);
  }
  12.5% {
    transform: translateX(-11vw) translateY(-42vh) scale(0.32);
  }
  16.66667% {
    transform: translateX(34vw) translateY(-39vh) scale(0.39);
  }
  20.83333% {
    transform: translateX(-15vw) translateY(10vh) scale(0.35);
  }
  25% {
    transform: translateX(9vw) translateY(-31vh) scale(0.26);
  }
  29.16667% {
    transform: translateX(-46vw) translateY(7vh) scale(0.79);
  }
  33.33333% {
    transform: translateX(45vw) translateY(-26vh) scale(0.42);
  }
  37.5% {
    transform: translateX(-43vw) translateY(34vh) scale(0.98);
  }
  41.66667% {
    transform: translateX(36vw) translateY(28vh) scale(0.53);
  }
  45.83333% {
    transform: translateX(-27vw) translateY(-27vh) scale(0.83);
  }
  50% {
    transform: translateX(-42vw) translateY(-5vh) scale(0.75);
  }
  54.16667% {
    transform: translateX(35vw) translateY(29vh) scale(0.47);
  }
  58.33333% {
    transform: translateX(37vw) translateY(7vh) scale(0.27);
  }
  62.5% {
    transform: translateX(28vw) translateY(31vh) scale(0.74);
  }
  66.66667% {
    transform: translateX(15vw) translateY(-18vh) scale(0.7);
  }
  70.83333% {
    transform: translateX(-42vw) translateY(34vh) scale(0.73);
  }
  75% {
    transform: translateX(45vw) translateY(-20vh) scale(0.69);
  }
  79.16667% {
    transform: translateX(29vw) translateY(43vh) scale(0.95);
  }
  83.33333% {
    transform: translateX(-14vw) translateY(-45vh) scale(0.67);
  }
  87.5% {
    transform: translateX(28vw) translateY(4vh) scale(0.77);
  }
  91.66667% {
    transform: translateX(-31vw) translateY(-7vh) scale(0.38);
  }
  95.83333% {
    transform: translateX(-2vw) translateY(-10vh) scale(0.69);
  }
  100% {
    transform: translateX(-2vw) translateY(26vh) scale(0.79);
  }
}

.firefly:nth-child(24) {
  animation-name: move24;
}
.firefly:nth-child(24)::before {
  animation-duration: 10s;
}
.firefly:nth-child(24)::after {
  animation-duration: 10s, 8691ms;
  animation-delay: 0ms, 6726ms;
}

@keyframes move24 {
  0% {
    transform: translateX(-34vw) translateY(-16vh) scale(0.91);
  }
  3.7037% {
    transform: translateX(-48vw) translateY(-7vh) scale(0.38);
  }
  7.40741% {
    transform: translateX(-14vw) translateY(-30vh) scale(0.7);
  }
  11.11111% {
    transform: translateX(-34vw) translateY(1vh) scale(0.26);
  }
  14.81481% {
    transform: translateX(0vw) translateY(-7vh) scale(0.57);
  }
  18.51852% {
    transform: translateX(50vw) translateY(5vh) scale(0.73);
  }
  22.22222% {
    transform: translateX(-28vw) translateY(48vh) scale(0.65);
  }
  25.92593% {
    transform: translateX(35vw) translateY(40vh) scale(0.76);
  }
  29.62963% {
    transform: translateX(-44vw) translateY(44vh) scale(0.71);
  }
  33.33333% {
    transform: translateX(-29vw) translateY(-1vh) scale(0.32);
  }
  37.03704% {
    transform: translateX(29vw) translateY(18vh) scale(0.69);
  }
  40.74074% {
    transform: translateX(32vw) translateY(-43vh) scale(0.55);
  }
  44.44444% {
    transform: translateX(31vw) translateY(-9vh) scale(0.9);
  }
  48.14815% {
    transform: translateX(40vw) translateY(-23vh) scale(0.59);
  }
  51.85185% {
    transform: translateX(27vw) translateY(5vh) scale(0.71);
  }
  55.55556% {
    transform: translateX(49vw) translateY(-40vh) scale(0.33);
  }
  59.25926% {
    transform: translateX(-9vw) translateY(41vh) scale(0.33);
  }
  62.96296% {
    transform: translateX(-3vw) translateY(-45vh) scale(0.37);
  }
  66.66667% {
    transform: translateX(-23vw) translateY(6vh) scale(0.78);
  }
  70.37037% {
    transform: translateX(10vw) translateY(-34vh) scale(0.67);
  }
  74.07407% {
    transform: translateX(41vw) translateY(-4vh) scale(0.56);
  }
  77.77778% {
    transform: translateX(30vw) translateY(-9vh) scale(0.49);
  }
  81.48148% {
    transform: translateX(-19vw) translateY(-28vh) scale(0.38);
  }
  85.18519% {
    transform: translateX(-41vw) translateY(3vh) scale(0.82);
  }
  88.88889% {
    transform: translateX(-48vw) translateY(-18vh) scale(0.55);
  }
  92.59259% {
    transform: translateX(25vw) translateY(48vh) scale(0.96);
  }
  96.2963% {
    transform: translateX(-38vw) translateY(-37vh) scale(0.63);
  }
  100% {
    transform: translateX(23vw) translateY(13vh) scale(0.31);
  }
}

.firefly:nth-child(25) {
  animation-name: move25;
}
.firefly:nth-child(25)::before {
  animation-duration: 13s;
}
.firefly:nth-child(25)::after {
  animation-duration: 13s, 10338ms;
  animation-delay: 0ms, 3575ms;
}

@keyframes move25 {
  0% {
    transform: translateX(-27vw) translateY(-45vh) scale(0.87);
  }
  4.7619% {
    transform: translateX(9vw) translateY(40vh) scale(0.45);
  }
  9.52381% {
    transform: translateX(31vw) translateY(7vh) scale(0.84);
  }
  14.28571% {
    transform: translateX(-30vw) translateY(9vh) scale(0.68);
  }
  19.04762% {
    transform: translateX(0vw) translateY(-33vh) scale(0.53);
  }
  23.80952% {
    transform: translateX(32vw) translateY(-40vh) scale(0.78);
  }
  28.57143% {
    transform: translateX(16vw) translateY(-35vh) scale(0.98);
  }
  33.33333% {
    transform: translateX(22vw) translateY(-20vh) scale(0.35);
  }
  38.09524% {
    transform: translateX(-21vw) translateY(37vh) scale(0.93);
  }
  42.85714% {
    transform: translateX(19vw) translateY(46vh) scale(0.6);
  }
  47.61905% {
    transform: translateX(49vw) translateY(-14vh) scale(0.78);
  }
  52.38095% {
    transform: translateX(7vw) translateY(43vh) scale(0.57);
  }
  57.14286% {
    transform: translateX(2vw) translateY(0vh) scale(0.77);
  }
  61.90476% {
    transform: translateX(18vw) translateY(16vh) scale(0.39);
  }
  66.66667% {
    transform: translateX(19vw) translateY(38vh) scale(0.45);
  }
  71.42857% {
    transform: translateX(18vw) translateY(-27vh) scale(0.72);
  }
  76.19048% {
    transform: translateX(-2vw) translateY(-44vh) scale(0.51);
  }
  80.95238% {
    transform: translateX(-47vw) translateY(36vh) scale(0.63);
  }
  85.71429% {
    transform: translateX(-25vw) translateY(24vh) scale(0.8);
  }
  90.47619% {
    transform: translateX(-13vw) translateY(14vh) scale(0.59);
  }
  95.2381% {
    transform: translateX(-47vw) translateY(-47vh) scale(0.65);
  }
  100% {
    transform: translateX(12vw) translateY(17vh) scale(0.85);
  }
}

.firefly:nth-child(26) {
  animation-name: move26;
}
.firefly:nth-child(26)::before {
  animation-duration: 10s;
}
.firefly:nth-child(26)::after {
  animation-duration: 10s, 9707ms;
  animation-delay: 0ms, 2531ms;
}

@keyframes move26 {
  0% {
    transform: translateX(-18vw) translateY(-10vh) scale(0.49);
  }
  4.7619% {
    transform: translateX(-31vw) translateY(-40vh) scale(0.68);
  }
  9.52381% {
    transform: translateX(-17vw) translateY(17vh) scale(0.75);
  }
  14.28571% {
    transform: translateX(-18vw) translateY(-13vh) scale(0.55);
  }
  19.04762% {
    transform: translateX(47vw) translateY(6vh) scale(0.32);
  }
  23.80952% {
    transform: translateX(-24vw) translateY(10vh) scale(0.38);
  }
  28.57143% {
    transform: translateX(46vw) translateY(43vh) scale(0.86);
  }
  33.33333% {
    transform: translateX(-41vw) translateY(-23vh) scale(0.6);
  }
  38.09524% {
    transform: translateX(13vw) translateY(36vh) scale(0.4);
  }
  42.85714% {
    transform: translateX(-22vw) translateY(27vh) scale(0.64);
  }
  47.61905% {
    transform: translateX(37vw) translateY(36vh) scale(0.87);
  }
  52.38095% {
    transform: translateX(-4vw) translateY(-6vh) scale(0.33);
  }
  57.14286% {
    transform: translateX(-18vw) translateY(41vh) scale(0.69);
  }
  61.90476% {
    transform: translateX(19vw) translateY(-4vh) scale(0.3);
  }
  66.66667% {
    transform: translateX(-47vw) translateY(31vh) scale(0.51);
  }
  71.42857% {
    transform: translateX(14vw) translateY(6vh) scale(0.83);
  }
  76.19048% {
    transform: translateX(-46vw) translateY(-15vh) scale(0.29);
  }
  80.95238% {
    transform: translateX(-23vw) translateY(-36vh) scale(0.76);
  }
  85.71429% {
    transform: translateX(-44vw) translateY(-27vh) scale(0.52);
  }
  90.47619% {
    transform: translateX(-38vw) translateY(-32vh) scale(0.66);
  }
  95.2381% {
    transform: translateX(38vw) translateY(1vh) scale(0.98);
  }
  100% {
    transform: translateX(-15vw) translateY(7vh) scale(0.65);
  }
}

.firefly:nth-child(27) {
  animation-name: move27;
}
.firefly:nth-child(27)::before {
  animation-duration: 16s;
}
.firefly:nth-child(27)::after {
  animation-duration: 16s, 10803ms;
  animation-delay: 0ms, 992ms;
}

@keyframes move27 {
  0% {
    transform: translateX(24vw) translateY(38vh) scale(0.77);
  }
  4.34783% {
    transform: translateX(22vw) translateY(31vh) scale(0.31);
  }
  8.69565% {
    transform: translateX(42vw) translateY(-43vh) scale(0.86);
  }
  13.04348% {
    transform: translateX(15vw) translateY(-46vh) scale(0.89);
  }
  17.3913% {
    transform: translateX(49vw) translateY(-33vh) scale(0.57);
  }
  21.73913% {
    transform: translateX(26vw) translateY(-37vh) scale(0.26);
  }
  26.08696% {
    transform: translateX(-20vw) translateY(18vh) scale(0.57);
  }
  30.43478% {
    transform: translateX(-29vw) translateY(-43vh) scale(0.43);
  }
  34.78261% {
    transform: translateX(-49vw) translateY(-20vh) scale(0.3);
  }
  39.13043% {
    transform: translateX(-11vw) translateY(-26vh) scale(0.57);
  }
  43.47826% {
    transform: translateX(-34vw) translateY(5vh) scale(1);
  }
  47.82609% {
    transform: translateX(-24vw) translateY(-42vh) scale(0.8);
  }
  52.17391% {
    transform: translateX(-31vw) translateY(-22vh) scale(0.8);
  }
  56.52174% {
    transform: translateX(-24vw) translateY(1vh) scale(0.52);
  }
  60.86957% {
    transform: translateX(-24vw) translateY(-30vh) scale(0.37);
  }
  65.21739% {
    transform: translateX(2vw) translateY(28vh) scale(0.73);
  }
  69.56522% {
    transform: translateX(7vw) translateY(-4vh) scale(0.85);
  }
  73.91304% {
    transform: translateX(36vw) translateY(36vh) scale(0.44);
  }
  78.26087% {
    transform: translateX(23vw) translateY(-24vh) scale(0.5);
  }
  82.6087% {
    transform: translateX(-5vw) translateY(-20vh) scale(0.41);
  }
  86.95652% {
    transform: translateX(-21vw) translateY(41vh) scale(0.7);
  }
  91.30435% {
    transform: translateX(-26vw) translateY(17vh) scale(0.39);
  }
  95.65217% {
    transform: translateX(13vw) translateY(-26vh) scale(0.93);
  }
  100% {
    transform: translateX(-33vw) translateY(-26vh) scale(0.83);
  }
}

.firefly:nth-child(28) {
  animation-name: move28;
}
.firefly:nth-child(28)::before {
  animation-duration: 15s;
}
.firefly:nth-child(28)::after {
  animation-duration: 15s, 6518ms;
  animation-delay: 0ms, 3756ms;
}

@keyframes move28 {
  0% {
    transform: translateX(18vw) translateY(9vh) scale(0.39);
  }
  5% {
    transform: translateX(41vw) translateY(-27vh) scale(0.78);
  }
  10% {
    transform: translateX(-7vw) translateY(-46vh) scale(0.72);
  }
  15% {
    transform: translateX(-14vw) translateY(-48vh) scale(0.63);
  }
  20% {
    transform: translateX(17vw) translateY(27vh) scale(0.37);
  }
  25% {
    transform: translateX(-34vw) translateY(37vh) scale(0.59);
  }
  30% {
    transform: translateX(-11vw) translateY(-36vh) scale(0.62);
  }
  35% {
    transform: translateX(9vw) translateY(-41vh) scale(0.7);
  }
  40% {
    transform: translateX(-34vw) translateY(7vh) scale(0.5);
  }
  45% {
    transform: translateX(36vw) translateY(17vh) scale(0.27);
  }
  50% {
    transform: translateX(47vw) translateY(39vh) scale(0.86);
  }
  55% {
    transform: translateX(25vw) translateY(7vh) scale(0.31);
  }
  60% {
    transform: translateX(40vw) translateY(11vh) scale(0.69);
  }
  65% {
    transform: translateX(8vw) translateY(33vh) scale(0.28);
  }
  70% {
    transform: translateX(-26vw) translateY(-15vh) scale(0.86);
  }
  75% {
    transform: translateX(22vw) translateY(24vh) scale(0.91);
  }
  80% {
    transform: translateX(-2vw) translateY(7vh) scale(0.98);
  }
  85% {
    transform: translateX(8vw) translateY(31vh) scale(0.69);
  }
  90% {
    transform: translateX(36vw) translateY(-40vh) scale(0.81);
  }
  95% {
    transform: translateX(28vw) translateY(35vh) scale(0.36);
  }
  100% {
    transform: translateX(-10vw) translateY(20vh) scale(0.35);
  }
}

.firefly:nth-child(29) {
  animation-name: move29;
}
.firefly:nth-child(29)::before {
  animation-duration: 10s;
}
.firefly:nth-child(29)::after {
  animation-duration: 10s, 7163ms;
  animation-delay: 0ms, 8027ms;
}

@keyframes move29 {
  0% {
    transform: translateX(0vw) translateY(-1vh) scale(0.96);
  }
  4.34783% {
    transform: translateX(-38vw) translateY(28vh) scale(0.63);
  }
  8.69565% {
    transform: translateX(-38vw) translateY(13vh) scale(0.9);
  }
  13.04348% {
    transform: translateX(27vw) translateY(-42vh) scale(0.36);
  }
  17.3913% {
    transform: translateX(18vw) translateY(-44vh) scale(0.38);
  }
  21.73913% {
    transform: translateX(24vw) translateY(-10vh) scale(0.38);
  }
  26.08696% {
    transform: translateX(31vw) translateY(28vh) scale(0.45);
  }
  30.43478% {
    transform: translateX(18vw) translateY(-45vh) scale(0.39);
  }
  34.78261% {
    transform: translateX(7vw) translateY(-10vh) scale(0.82);
  }
  39.13043% {
    transform: translateX(0vw) translateY(-27vh) scale(0.78);
  }
  43.47826% {
    transform: translateX(-41vw) translateY(-28vh) scale(0.55);
  }
  47.82609% {
    transform: translateX(-43vw) translateY(31vh) scale(0.86);
  }
  52.17391% {
    transform: translateX(28vw) translateY(41vh) scale(0.64);
  }
  56.52174% {
    transform: translateX(29vw) translateY(-16vh) scale(0.33);
  }
  60.86957% {
    transform: translateX(17vw) translateY(7vh) scale(0.46);
  }
  65.21739% {
    transform: translateX(-7vw) translateY(0vh) scale(0.39);
  }
  69.56522% {
    transform: translateX(7vw) translateY(-14vh) scale(0.85);
  }
  73.91304% {
    transform: translateX(-42vw) translateY(29vh) scale(0.86);
  }
  78.26087% {
    transform: translateX(-49vw) translateY(7vh) scale(0.85);
  }
  82.6087% {
    transform: translateX(-11vw) translateY(-46vh) scale(0.45);
  }
  86.95652% {
    transform: translateX(-28vw) translateY(-48vh) scale(0.41);
  }
  91.30435% {
    transform: translateX(-46vw) translateY(-15vh) scale(0.78);
  }
  95.65217% {
    transform: translateX(27vw) translateY(22vh) scale(0.77);
  }
  100% {
    transform: translateX(-24vw) translateY(33vh) scale(0.48);
  }
}

.firefly:nth-child(30) {
  animation-name: move30;
}
.firefly:nth-child(30)::before {
  animation-duration: 15s;
}
.firefly:nth-child(30)::after {
  animation-duration: 15s, 7724ms;
  animation-delay: 0ms, 3235ms;
}

@keyframes move30 {
  0% {
    transform: translateX(-20vw) translateY(-42vh) scale(0.72);
  }
  3.84615% {
    transform: translateX(11vw) translateY(-34vh) scale(0.59);
  }
  7.69231% {
    transform: translateX(17vw) translateY(-21vh) scale(0.98);
  }
  11.53846% {
    transform: translateX(2vw) translateY(35vh) scale(0.6);
  }
  15.38462% {
    transform: translateX(33vw) translateY(-13vh) scale(0.97);
  }
  19.23077% {
    transform: translateX(-46vw) translateY(33vh) scale(0.54);
  }
  23.07692% {
    transform: translateX(27vw) translateY(43vh) scale(0.57);
  }
  26.92308% {
    transform: translateX(47vw) translateY(-9vh) scale(0.9);
  }
  30.76923% {
    transform: translateX(31vw) translateY(-22vh) scale(0.82);
  }
  34.61538% {
    transform: translateX(24vw) translateY(-14vh) scale(0.27);
  }
  38.46154% {
    transform: translateX(5vw) translateY(-20vh) scale(0.58);
  }
  42.30769% {
    transform: translateX(-19vw) translateY(-34vh) scale(0.62);
  }
  46.15385% {
    transform: translateX(-19vw) translateY(14vh) scale(0.93);
  }
  50% {
    transform: translateX(43vw) translateY(-17vh) scale(0.8);
  }
  53.84615% {
    transform: translateX(-31vw) translateY(-13vh) scale(0.85);
  }
  57.69231% {
    transform: translateX(-16vw) translateY(27vh) scale(0.53);
  }
  61.53846% {
    transform: translateX(-9vw) translateY(27vh) scale(0.91);
  }
  65.38462% {
    transform: translateX(-10vw) translateY(-13vh) scale(0.95);
  }
  69.23077% {
    transform: translateX(-15vw) translateY(-33vh) scale(0.85);
  }
  73.07692% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.82);
  }
  76.92308% {
    transform: translateX(10vw) translateY(8vh) scale(0.33);
  }
  80.76923% {
    transform: translateX(36vw) translateY(-23vh) scale(0.67);
  }
  84.61538% {
    transform: translateX(39vw) translateY(19vh) scale(0.39);
  }
  88.46154% {
    transform: translateX(22vw) translateY(33vh) scale(0.37);
  }
  92.30769% {
    transform: translateX(-27vw) translateY(-32vh) scale(0.84);
  }
  96.15385% {
    transform: translateX(33vw) translateY(1vh) scale(0.36);
  }
  100% {
    transform: translateX(-18vw) translateY(0vh) scale(0.45);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}
